import React, { useState } from "react";
 
import { IoClose } from "react-icons/io5";
 

const PopoverHome = ({ isOpen, next,navtohome  }) => {
 
  

  const closePopover = () => {
    isOpen();
 };
 const sentohome = () => {
  navtohome();
};
 
  return (
    <div
      className="popover"
      // Apply zoom animation styles
      style={{
        animation: "zoomIn 0.5s",
        transformOrigin: "center",
      }}
    >
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
       
        <div className="popover-clicon" onClick={closePopover}>
       
          <IoClose></IoClose> 
        </div> 
      </div>
      <h1 className="text-center mb-fontsize21">
       Restart run?
      </h1>
      <p className="lightfont text-center mb-4">
        Are you sure you want to restart? The AI-generated designs on this page will be lost
      </p>
      <p className="lightfont text-center mb-4 flex gap-5 justify-center">
       <button className="backbtn" onClick={sentohome}>Yes</button>  <button className="backbtn" onClick={closePopover}> No</button>
      </p>
        

      
      

      <style>
        {`
          .popover {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 15px;
            padding: 20px;
            position: absolute; 
            animation-fill-mode: forwards;
            width:100%; 
            max-width:600px;
            min-height:300px;
          }
          .backbtn{
            width:100px; min-width:100px;
          }
          @keyframes zoomIn {
            from { transform: scale(0) }
            to { transform: scale(1) }
          }
        `}
      </style>
    </div>
  );
};

export default PopoverHome;
