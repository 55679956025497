import React, { createContext, useContext, useState } from "react";

// Create the context
const FormContext = createContext();

export const useForms = () => { return useContext(FormContext);};

export const FormProvider = ({ children }) => {
 
  const [formData, setFormData] = useState({
     MainCategories: {},
     DesignRing:{},
     CenterStone: {},
     DesignCrown:{},
     DesignShank:{},
     MetalType:{},
     DesignAesthetic:{},
     CustomRing:{},
     QuotePrice:{},
     QuickInfo:{}, 
     ImagesData:{}, 
     CenterStoneSingle:{},
     DressStyle:{},
     DesignMotif:{}, 
     BraceletStyle:{},
     EarringsCrown:{},
     PendentStyle:{},
     WeddingCrown:{},
     WeddingStyle:{},
     showPricepover:{},
     Style:{} 
  });
  const resetFormData = () => {
    setFormData({
      MainCategories: {},
      DesignRing:{},
      CenterStone: {},
      DesignCrown:{},
      DesignShank:{},
      MetalType:{},
      DesignAesthetic:{},
      CustomRing:{},
      QuotePrice:{},
      QuickInfo:{}, 
      ImagesData:{}, 
      ImagesData2:{}, 
      CenterStoneSingle:{},
      DressStyle:{},
      DesignMotif:{}, 
      BraceletStyle:{},
      EarringsCrown:{},
      PendentStyle:{},
      WeddingCrown:{},
      WeddingStyle:{},        
      Style:{} 
    });
  }
  const [apiurl, setapiurl] = useState("https://app.jewelcounter.com"); //useState("https://cadfolio.net"); 
  const params = new URLSearchParams(window.location.search);
  
  var bearertoken = params.get("bt"); 
  if(bearertoken==undefined || bearertoken==null){
     bearertoken= 'faafbcaae19552956f813ed955ea3242'; 
  }
 
  const updateFormData = (compName, data) => {
    setFormData(prevData => ({
      ...prevData,
      [compName]: data
    }));
  };

  return (
    <FormContext.Provider   value={{ formData, updateFormData,apiurl,bearertoken,resetFormData }}>
      {children}
    </FormContext.Provider>
  );
};
