import React, { useState, useEffect } from "react";
import SocialMedia from "../shared/SocialMedia";
import { FiDownload } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";
import ImgSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useForms } from "../actions/FormContext";
function Mainimage({ tradername }) {
  const [social, setSocial] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const { formData, updateFormData, apiurl } = useForms();
  const [activeImage, setActiveImage] = useState(null); // State to hold the active image

  const handleDownload = () => {
    if   (formData.CustomRing?.myring !== undefined) {
      donwloadimg1();
    } else {
    }
  };
  const donwloadimg1 = () => {
    let filename =
      "my_custom_" + formData.MainCategories.category_name + ".jpg";
    filename = filename.toLowerCase(filename);
    filename = filename.toLowerCase(filename);
    filename = filename.replace(/ /g, "_");

    const link = document.createElement("a");
    link.href =
      apiurl + "/ring-builder/download/" + formData.CustomRing.selectedimage;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloaded(true);
    setTimeout(() => setDownloaded(false), 5000);
  };
  
  const handleShare = () => {
    //  console.log(formData);
    setSocial(!social);
    // if (navigator.share) {
    //   navigator
    //     .share({
    //       title: "Image",
    //       text: "Check out this image",
    //       url: formData.CustomRing.myring,
    //     })
    //     .then(() => console.log("Image shared successfully"))
    //     .catch((error) => console.log("Error sharing image", error));
    // }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    afterChange: function (currentSlide) {
      const images = [
        formData.CustomRing?.myring,
        
      ];
      setActiveImage(images[currentSlide]);
    },
  };
  useEffect(() => {
    updateFormData("getMainimage", {
      media: activeImage,
    });
  }, [activeImage]);

  return (
    <>
      <div
        style={{
          height: "0",
          zIndex: "9",
          position: "relative",
          textAlign: "center",
        }}
      >
        {" "}
        {downloaded && (
          <span className="  text-green-500">
            Your image is being downloaded automatically
          </span>
        )}
      </div>
      <div className="mainImage relative mb-mb-20">
        <div className="bg-white">
       
                <img
                src={formData.CustomRing?.myring}
                alt={`Selected image `}
                className="w-full"
                key="myring"
              />
            
             
           

         
        </div>

        <div className="sharedicons">
          <div className="cursor-pointer fileshare">
            <FiDownload onClick={handleDownload}></FiDownload>
          </div>
          <div className="cursor-pointer fileshare relative">
            <BsFillShareFill onClick={handleShare}></BsFillShareFill>
            {social && (
              <div className="scoialiconslist">
                <SocialMedia tradername={tradername}></SocialMedia>
              </div>
            )}
          </div>
        </div>

        {/* {activeImage && <p>Active image URL: {activeImage}</p>} */}
      </div>
    </>
  );
}

export default Mainimage;
