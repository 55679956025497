import React,{useState} from 'react'
import { FaLink } from "react-icons/fa6"; 

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon, 
    FacebookShareButton, 
    PinterestIcon,
    PinterestShareButton,     
    TwitterShareButton,    
    WhatsappIcon,
    WhatsappShareButton,    
    XIcon,
  } from "react-share";
  import { useForms } from "../actions/FormContext";
function SocialMedia({tradername}) {
  const [copytext, setcopytext] = useState(false);
    const {formData} = useForms();
    //const [mainimg, setMainImg] = useState(formData.getMainimage?.media);
   const myimg = formData.CustomRing.myring;
   
   // const myimg=formData.getMainimage?.media;     
    const urlToCopy = myimg;
    const shareUrl = myimg;
    const title = formData.MainCategories.category_name;  

  const handleButtonClick = () => {
    navigator.clipboard.writeText(urlToCopy)
      .then(() => {
       // alert('Link copied to clipboard');
       setcopytext(!copytext)
       setTimeout( () =>{
        setcopytext(false)
       },2000 )
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    
    <div className='flex ' style={{ "columnGap":"10px"}}>
     
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body={"Please check this URL for an excellent design of : "+ title +"\n"}
          className="Demo__some-network__share-button">
          <EmailIcon size={32} round />
        </EmailShareButton>
        <div className="linkicon" style={{display:"flex",color:'#fff', justifyContent:"center", alignItems:"center", background:"#f97400", borderRadius:"50%", width:"30px", height:"30px"}}> 
        <FaLink onClick={handleButtonClick}> </FaLink>
       { copytext ? <span className='copitedtext'>Link copied</span> :"" }
        </div>
          
        <FacebookShareButton
          url={shareUrl}
          className="Demo__some-network__share-button">
          <FacebookIcon size={32} round />
        </FacebookShareButton> 

        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"        >
          <XIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={shareUrl}
          title={`Hey, check out this ${title} which I designed using ${tradername}'s latest AI design app`}
          separator=": "
          className="Demo__some-network__share-button">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <PinterestShareButton
          url={String(shareUrl)}
          media={`${String(shareUrl)}`}
          className="Demo__some-network__share-button">
          <PinterestIcon size={32} round />
        </PinterestShareButton> 

    </div>
  )
}

export default SocialMedia